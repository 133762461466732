import React from "react"
import View from "./View"

function Withdrawal() {
  return <View />
}

export default {
  routeProps: {
    path: "/saque",
    exact: true,
    type: "private", //TODO: change to private
    component: Withdrawal,
  },
  name: "Withdrawal",
}
