import AuthLogin from "./AuthLogin"
import Dashboard from "./Dashboard"
import ForgotPassword from "./ForgotPassword"
import Recover from "./Recover"
import Register from "./Register"
import Withdrawal from "./Withdrawal"

export default [
  AuthLogin,
  Dashboard,
  Register,
  Withdrawal,
  Recover,
  ForgotPassword,
]
