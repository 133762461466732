import React, { useEffect } from "react"
import View from "./View"
import { getInfo } from "~/services/affiliates"
import { SET_INFO } from "~/constants"
import { useAppDispatch } from "~/context"

function Dashboard() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    async function fetchInfo() {
      try {
        const payload = await getInfo()
        const { data } = payload

        dispatch({
          type: SET_INFO,
          payload: { data: { ...data } },
        })
      } catch (error) {
        console.log(error)
      }
    }
    fetchInfo()
  }, [dispatch])

  return <View />
}

export default {
  routeProps: {
    path: "/",
    exact: true,
    type: "private",
    component: Dashboard,
  },
  name: "Dashboard",
}
