import React from "react"
import { useAppState } from "~/context"
import View from "./View"

function Alert() {
  const { alert } = useAppState()

  return (
    <View open={alert.open} message={alert.message} variant={alert.variant} />
  )
}

export default Alert
