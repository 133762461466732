import React from "react"
import PropTypes from "prop-types"
import {
  SET_USER,
  SET_ALERT,
  LOADING,
  SET_INFO,
  SET_REGISTER,
  SET_WITHDRAWAL,
} from "./constants"

const AppStateContext = React.createContext()
const AppDispatchContext = React.createContext()

function AppReducer(state, action) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: { data: action.payload, isFetching: false } }
    }
    case SET_ALERT: {
      return { ...state, alert: { ...state.alert, ...action.payload } }
    }
    case SET_INFO: {
      return { ...state, info: { ...state.info, ...action.payload } }
    }
    case LOADING: {
      return { ...state, loading: action.payload }
    }
    case SET_REGISTER: {
      return { ...state, register: action.payload }
    }
    case SET_WITHDRAWAL: {
      return { ...state, withdrawal: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppProvider({ children }) {
  const initialState = {
    user: {
      data: null,
      isFetching: true,
    },
    alert: {
      open: false,
      message: "",
      variant: "",
    },
    info: {
      data: {},
      isFetching: true,
    },
    withdrawal: {
      data: [],
      isFetching: true,
    },
    loading: true,
    register: {
      data: {
        bank_account: {
          bank_code: "",
          agency_number: "",
          agency_digit: "",
          account_number: "",
          account_digit: "",
          account_type: "",
          document_number: "",
          document_type: "corporation",
          owner_name: "",
        }, //dadoss bancários
        partners: [], //sócios
      },
      bankList: [],
      isDisabled: true,
    },
  }
  const [state, dispatch] = React.useReducer(AppReducer, initialState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider")
  }
  return context
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider")
  }
  return context
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export { AppProvider, useAppState, useAppDispatch }
