import React, { useEffect } from "react"
import { SET_REGISTER } from "~/constants"
import { useAppDispatch, useAppState } from "~/context"
import View from "./View"

export default function Partners() {
  const { register } = useAppState()
  const dispatch = useAppDispatch()

  const [partnersList, setPartnersList] = React.useState([])

  useEffect(() => {
    setPartnersList(
      !register.data.partners.length
        ? [
            {
              name: "",
              email: "",
              document_number: "",
            },
          ]
        : register.data.partners
    )
  }, [register.data.partners])

  const handleChange = (index, name, value) => {
    partnersList[index][name] = value

    dispatch({
      type: SET_REGISTER,
      payload: {
        data: { ...register.data, partners: partnersList },
        bankList: register.bankList,
      },
    })
  }

  const addMorePartner = () => {
    setPartnersList((partnersList) => [
      ...partnersList,
      {
        name: "",
        email: "",
        document_number: "",
      },
    ])
  }

  const removeLastPartner = () => {
    const newList = partnersList

    newList.pop()
    setPartnersList(() => [...newList])
  }

  return (
    <View
      state={{
        partnersList,
        data: register.data.partners,
      }}
      actions={{
        handleChange,
        addMorePartner,
        removeLastPartner,
      }}
    />
  )
}
