import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { Card } from "../Card"
import ButtonWithdraw from "../Button"
import CreateNewWithdraw from "../CreateNewWithdrawal"
import AddIcon from "@material-ui/icons/Add"

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    fontFamily: "Nunito Sans",
    "& h6": {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left",
      margin: 0,
    },
  },
  newWithdrawal: {
    marginTop: "50px",
    borderRadius: "8px !important",
    color: "#4C6FFF",
    border: "2px solid #4C6FFF",
    backgroundColor: "#4C6FFF00",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    // width: 180,
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
      border: "2px solid #4C6FFF",
      backgroundColor: "#4C6FFF00",
    },
  },
}))

export default function View({
  state: { list, open, page },
  actions: { setOpen, setPage },
}) {
  const classes = useStyles()
  return (
    <Box style={{ margin: "65px 171px" }}>
      <Box mb={4} className={classes.header}>
        <Typography variant="h6" gutterBottom>
          Meus Saques
        </Typography>
        <Box display="flex">
          <ButtonWithdraw
            state={{
              variant: "contained",
              style: "newWithdrawal",
              text: "Novo Saque",
              icon: <AddIcon />,
            }}
            action={{ handleClick: () => setOpen(true) }}
          />
        </Box>
      </Box>
      {list && list.length ? (
        <Box marginTop="2rem">
          {list.map((withdrawal, index) => {
            if (index < page * 6)
              return (
                <Box key={index}>
                  <Card state={{ item: withdrawal }} />
                </Box>
              )

            return null
          })}
          {list.length > 6 ? 
          <Box display="flex" justifyContent="center" marginTop="50px">
            <ButtonWithdraw
              state={{
                variant: "contained",
                style: "loadMore",
                text: "Carregar Mais",
              }}
              action={{ handleClick: () => setPage(page + 1) }}
            />
          </Box> : ""
          }
        </Box>
      ) : (
        <Box my={2} textAlign="center">
          <Typography variant="h6" gutterBottom>
            Nenhum saque foi encontrado
          </Typography>
        </Box>
      )}

      <CreateNewWithdraw state={{
        open, page, count: 6
      }} action={{
        handleClose: setOpen
      }} />
    </Box>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    list: PropTypes.any,
    open: PropTypes.any,
    page: PropTypes.any,
  }),
  actions: PropTypes.shape({
    setOpen: PropTypes.func,
    setPage: PropTypes.func,
  }),
}
