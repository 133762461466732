import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { useAppDispatch } from "~/context"
import { SET_ALERT } from "~/constants"
import PropTypes from "prop-types"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

function View({ open, message, variant }) {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  function handleClose() {
    dispatch({ type: SET_ALERT, payload: { open: false } })
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

View.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.string,
}

export default View
