import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Box, Typography } from "@material-ui/core"
import { useClipboardCopy } from "~/hooks/useClipBoardCopy"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "14px 20px",
    fontFamily: "Nunito Sans",
    textAlign: "left",
    color: theme.palette.text.secondary,
    margin: `${theme.spacing(3)}px 0`,
    "& .h4": {
      color: theme.palette.text.primary,
    },
    boxShadow:
      "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
    borderRadius: 8,
    cursor: 'pointer',
  },
  overview: {
    fontFamily: "Nunito Sans",
    position: "relative",
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: theme.spacing(2),
    color: "#2A2B4B",
    "&::before": {
      content: `''`,
      display: "block",
      height: "100%",
      width: "6px",
      position: "absolute",
      left: 0,
      background: `${theme.palette.primary.main}`,
      borderRadius: "18px",
    },
  },
  description: {
    fontFamily: "Nunito Sans",
    color: "#A0A4A8",
    paddingLeft: theme.spacing(2),
    fontSize: 14,
  },
}))

export default function LinkElement({ title, path, description }) {
  const classes = useStyles()
  const {handleCopyClick} = useClipboardCopy()

  return (
    <Paper className={classes.paper} onClick={() => handleCopyClick(path)}>
      <Box mb={1} className={classes.overview}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body2">{path}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1" className={classes.description}>
          {description}
        </Typography>
      </Box>
    </Paper>
  )
}

LinkElement.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
}
