/* eslint-disable react/prop-types */
import useAlert from "./useAlert";

export function useClipboardCopy() {  
    const {triggerAlert} = useAlert()
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      }
    }
  
    const handleCopyClick = (copyText) => {
      copyTextToClipboard(copyText)
        .then(() => {
          triggerAlert({message: 'Copiado!'})
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return {
        handleCopyClick
    }
  }