import React from "react"
import PropTypes from "prop-types"
import LoadingPage from "~/shared/components/LoadingPage"
import LinkElement from "../LinkElement"
import { Box } from "@material-ui/core"

export default function LinksList({ state: { linksList, isFetching } }) {
  if (isFetching) return <LoadingPage />

  return (
    <React.Fragment>
      {linksList.length ? (
        linksList.map((link, index) => (
          <LinkElement key={`${link.name}_${index}`} {...link} />
        ))
      ) : (
        <Box
          style={{
            fontWeight: 700,
            fontFamily: "Nunito Sans",
            textAlign: "center",
            fontSize: 20,
          }}
        >
          Nenhum link foi encontrado
        </Box>
      )}
    </React.Fragment>
  )
}

LinksList.propTypes = {
  state: PropTypes.shape({
    linksList: PropTypes.any,
    isFetching: PropTypes.bool,
  }),
}
