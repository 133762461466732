import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import { ReactComponent as Logo } from "~/assets/images/logo-y.svg"
import "./style.css"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    maxHeight: "70px",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    marginTop: theme.spacing(8),
    color: "white",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        MakeFunnels
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

function BackgroundAnimation() {
  return (
    <div className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default function View({
  state: { password_confirmation, password },
  actions: { handleChange, handleSubmit },
}) {
  const classes = useStyles()

  return (
    <>
      <BackgroundAnimation />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h3" className={classes.title}>
          MakeFunnels
        </Typography>
        <CssBaseline />
        <div className={classes.paper}>
          <Logo className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Mudança da senha
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={password}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password_confirmation"
              label="Confirmar Senha"
              type="password"
              id="password_confirmation"
              onChange={handleChange}
              value={password_confirmation}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              definir nova senha
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}
