import React from "react"
import { useAppState } from "~/context"
import Loading from "./Loading"

function LoadingContainer(props) {
  const { loading } = useAppState()

  return (
    <Loading
      loading={loading.show}
      withBackground={loading.background}
      {...props}
    />
  )
}

export default LoadingContainer
