import React, { useState } from "react"
import { useAppDispatch } from "~/context"
import { SET_USER, SET_ALERT, LOADING, USER_STATUS } from "~/constants"
import { getToken, getProfile } from "~/services/auth"
import * as localStorage from "~/utils/localStorage"
import axios from "~/services/config/axios"
import View from "./View"

function AuthLogin() {
  const dispatch = useAppDispatch()
  const [user, setUser] = useState({
    email: "",
    password: "",
  })

  function handleChange(event) {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()
    try {
      const response = await getToken(user)
      if (response.data.access_token) {
        localStorage.save(
          "@makeFunnels_affiliate/token",
          response.data.access_token
        )
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`
        const user = await getProfile()
        const {
          data: {
            data: { ...allValues },
          },
        } = user

        const { name, email, role, aff_status } = allValues
        const isActive = [USER_STATUS.ACTIVE, USER_STATUS.ANALYZING].includes(aff_status)

        dispatch({
          type: SET_USER,
          payload: {
            name,
            email,
            role,
            aff_status,
          },
        })

        return (
          isActive &&
          dispatch({
            type: SET_ALERT,
            payload: {
              open: true,
              message: "Login efetuado com sucesso",
              variant: "success",
            },
          })
        )
      }
    } catch (error) {
      dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message:
            "Usuário ou senha incorretos, verifique os dados informados.",
          variant: "error",
        },
      })
    }

    return dispatch({
      type: LOADING,
      payload: false,
    })
  }

  return (
    <View
      {...user}
      actions={{
        handleChange,
        handleSubmit,
      }}
    />
  )
}

export default {
  routeProps: {
    path: "/auth",
    exact: true,
    type: "guest",
    component: AuthLogin,
  },
  name: "AuthLogin",
}
