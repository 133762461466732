import React from "react"
import View from "./View"

function Register() {
  return (
    <View
    // {...state}
    // actions={{
    //   handleChange,
    //   handleSubmit,
    // }}
    />
  )
}

export default {
  routeProps: {
    path: "/register",
    exact: true,
    type: "guest",
    component: Register,
  },
  name: "Register",
}
