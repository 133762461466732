import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons/"
import { Typography, Container, Box, Tooltip } from "@material-ui/core"
import Bar from "./components/Bar"
import clsx from "clsx"
import { useAppState } from "~/context"
import { ROLES } from "~/constants"

const useStyles = makeStyles((theme) => ({
  title: {
    marginRight: theme.spacing(1),
    fontWeight: 700,
    fontFamily: "Nunito Sans",
    "&.commission": {
      fontWeight: 400,
    },
  },
  helperOutline: {
    color: theme.palette.secondary.main,
  },
}))

export default function View({ state: { commission } }) {
  const classes = useStyles()
  const { user } = useAppState()
  const isFreeAccount = user?.data?.role === ROLES.FREE
  
  return (
    <Container>
      {!isFreeAccount && (
        <React.Fragment>
          <Box display="flex" alignItems="center" my={4}>
            <Typography variant="h6" className={classes.title}>
              Seu progresso de comissionamento
            </Typography>
            <Tooltip title="Aumentaremos automaticamente sua comissão na medida que você aumentar suas assinaturas ativas. Acompanhe aqui o número de assinaturas ativas necessárias para cada porcentagem de comissão e quanto falta para você atingir a sua próxima comissão.">
              <HelpOutline fontSize="small" className={classes.helperOutline} />
            </Tooltip>
          </Box>

          <Box m={5} py={5}>
            <Bar />
          </Box>
        </React.Fragment>
      )}
      <Box display="flex" alignItems="center" my={4}>
        <Typography variant="h6" className={clsx(classes.title, "commission")}>
          Comissão Atual: {commission}%
        </Typography>
        <Tooltip title="Esta é a porcentagem que você receberá na sua próxima indicação.">
          <HelpOutline fontSize="small" className={classes.helperOutline} />
        </Tooltip>
      </Box>
    </Container>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    commission: PropTypes.number,
  }),
  //   status_values,
  //   balance_values
}
