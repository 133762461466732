import React, { useEffect, useState } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsAdapter from "@date-io/date-fns"
import { getProfile } from "~/services/auth"
import { useAppDispatch } from "~/context"
import { SET_USER, SET_ALERT } from "~/constants"
import * as localStorage from "~/utils/localStorage"
import "fontsource-montserrat/700.css"

import Routes from "./routes"
import theme from "./theme"
import Loading from "~/shared/components/Loading"
import LoadingPage from "~/shared/components/LoadingPage"
import "./style.css"
import Alert from "./shared/components/Alert"

function App() {
  const dispatch = useAppDispatch()
  const [isFetching, setIsFetching] = useState(true)
  const token = localStorage.get("@makeFunnels_affiliate/token")

  useEffect(() => {
    async function fetchUser() {
      try {
        const payload = await getProfile()
        const {
          data: {
            data: { ...allValues },
          },
        } = payload
        const { name, email, role, aff_status } = allValues
        dispatch({
          type: SET_USER,
          payload: {
            name,
            email,
            role,
            aff_status,
          },
        })
        setIsFetching(false)
      } catch (error) {
        localStorage.remove("@makeFunnels_affiliate/token")
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message: "Houve um problema com sua autenticação!",
            variant: "error",
          },
        })
        setIsFetching(false)
      }

      // setLoadUser(true)
    }

    if (token) {
      fetchUser()
    } else {
      setIsFetching(false)
    }
  }, [token, dispatch])

  if (isFetching) return <LoadingPage />
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsAdapter}>
        <Alert />
        <Loading />
        <Routes />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
