import { SET_ALERT, SET_LANG, SET_WITHDRAWAL } from "~/constants"
import axios from "./config/axios"
import { mountUrl } from "./helper"

export const getInfo = (page, count) =>
  axios.get(
    mountUrl({
      baseUrl: "/affiliates/withdraw",
      params: [
        { name: "page", value: page },
        { name: "count", value: count },
      ],
      lang: SET_LANG.LANGS.PT_BR,
    })
  )
export const getBankAccount = () => axios.get(`/affiliates/bank_account`)

export const createWithdraw = (data) =>
  axios.post(
    mountUrl({ baseUrl: "/affiliates/withdraw", lang: SET_LANG.LANGS.PT_BR }),
    data
  )

export async function FetchWithdrawal(dispatchApp, page, count) {
  dispatchApp({
    type: SET_WITHDRAWAL,
    payload: {
      data: [],
      isFetching: true,
    },
  })
  try {
    const response = await getInfo(page, count)
    const {
      data: {
        data: [...rest],
      },
    } = response
    dispatchApp({
      type: SET_WITHDRAWAL,
      payload: {
        isFetching: false,
        data: [...rest],
      },
    })
  } catch (err) {
    dispatchApp({
      type: SET_ALERT,
      payload: {
        open: true,
        message:
          "Houve um problema nesta operação. Por favor tente mais tarde.",
        variant: "error",
      },
    })
  }
}
