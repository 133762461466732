import React, { useEffect } from "react"
import View from "./View"
import { SET_ALERT } from "~/constants"
import { useAppDispatch, useAppState } from "~/context"
import { FetchProfile, finishRegistration } from "~/services/auth"
import { useHistory } from "react-router-dom"

export default function Form() {
  const dispatch = useAppDispatch()
  const { register } = useAppState()
  const [activeStep, setActiveStep] = React.useState(0)
  const [stepJump, setStepJump] = React.useState(1) // se vai pular 1 passo ou 2
  const [isCorporationAccount, setIsCorporationAccount] = React.useState(true)

  const history = useHistory()

  useEffect(() => {
    setIsCorporationAccount(
      register.data.bank_account.document_type === "corporation"
    )
    setStepJump(
      register.data.bank_account.document_type === "corporation" ? 1 : 2
    )
  }, [register.data.bank_account.document_type])

  const handleNext = () => {
    setActiveStep(activeStep + stepJump)
  }

  const handleBack = () => {
    setActiveStep(activeStep - stepJump)
  }

  // TEM COMO OBJETIVO ENVIAR OS DADOS DO CHECKOUT PARA A API
  const handleSubmit = async () => {
    try {
      await finishRegistration(register.data)
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err

      const erroArr = error[Object.keys(error)[0]]
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message:
            typeof error === "string" ? error : erroArr[erroArr.length - 1],
          variant: "error",
        },
      })
    }

    dispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: "Cadastro Finalizado",
        variant: "success",
      },
    })

    await FetchProfile(dispatch)
    return history.push("/")
  }

  return (
    <View
      state={{
        activeStep,
        isCorporationAccount,
      }}
      actions={{
        handleNext,
        handleBack,
        handleSubmit,
      }}
    />
  )
}
