import { SET_ALERT, SET_LANG, SET_USER } from "~/constants"
import axios from "./config/axios"
import { mountUrl } from "./helper"

export const getToken = ({ email, password }) =>
  axios.post(
    mountUrl({
      baseUrl: `/auth/affiliates/login`,
      lang: SET_LANG.LANGS.PT_BR,
    }),
    // "/auth/affiliates/login?lang=pt_BR"
    {
      email,
      password,
    }
  )

export const finishRegistration = (data) =>
  axios.post(
    mountUrl({
      baseUrl: "/affiliates/me/finish_registration",
      lang: SET_LANG.LANGS.PT_BR,
    }),
    data
  )

export const getProfile = () => axios.get("/auth/me")

export const logout = () => axios.get("/auth/logout")

export const recover = (token, email, data) =>
  axios.post(
    mountUrl({
      baseUrl: `affiliates/verify/${token}`,
      params: [
        { name: "email", value: email },
        { name: "affiliates", value: 1 },
      ],
    }),
    // `affiliates/verify/${token}?email=${email}`
    data
  )

export const resetPassword = (data) =>
  axios.post("auth/reset_password?affiliates=1", data)

export const getAvailableBanks = () => axios.get("affiliates/available_banks")

export async function FetchProfile(dispatch) {
  try {
    const payload = await getProfile()
    const {
      data: {
        data: { ...allValues },
      },
    } = payload
    const { name, email, role, aff_status } = allValues

    dispatch({
      type: SET_USER,
      payload: {
        name,
        email,
        role,
        aff_status,
      },
    })
  } catch (error) {
    localStorage.remove("@makeFunnels_affiliate/token")
    dispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: "Houve um problema com sua autenticação!",
        variant: "error",
      },
    })
  }
}
