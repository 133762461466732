import React, { useEffect, useState } from "react"
import View from "./View"

import { useAppState } from "~/context"
import LoadingPage from "~/shared/components/LoadingPage"

function OverviewContainer() {
  const { info } = useAppState()
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (Object.keys(info.data).length) setIsFetching(false)
  }, [info.data, isFetching])

  if (isFetching) return <LoadingPage />
  return (
    <View
      state={{
        info: info.data,
      }}
    />
  )
}

export default OverviewContainer
