export function mountUrl({ baseUrl, params = [], lang }) {
  let url = baseUrl

  if (params.length || lang.length) url += "?"

  if (params.length)
    params.forEach((param) => {
      url += `${param.name}=${param.value}&`
    })

  if (lang && lang.length) url += `lang=${lang}`

  return url
}
