import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { Box, Button, Container } from "@material-ui/core"
import InputMask from "react-input-mask"
import { useStyles } from "../styles"
import clsx from "clsx"

function InputGroup({
  state: { index, classes, data },
  action: { handleChange },
}) {
  return (
    <Grid container spacing={3}>
      <Typography className={classes.title} variant="h6" gutterBottom>
        Sócio {index + 1}
      </Typography>
      <Grid item xs={12} sm={12}>
        <TextField
          className={classes.input}
          fullWidth
          id={`name`}
          name={`name`}
          label="Nome"
          variant="outlined"
          value={data.name}
          onChange={(e) => {
            const { value, name } = e.target
            handleChange(index, name, value)
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          className={classes.input}
          fullWidth
          id={`email`}
          name={`email`}
          label="Email"
          variant="outlined"
          value={data.email}
          onChange={(e) => {
            const { value, name } = e.target
            handleChange(index, name, value)
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <InputMask
          mask="999.999.999-99"
          onChange={(e) => {
            const { value, name } = e.target
            handleChange(index, name, value)
          }}
          maskPlaceholder={null}
          value={data.document_number}
          className={classes.input}
        >
          <TextField
            fullWidth
            id={`document_number`}
            name={`document_number`}
            label="CPF"
            variant="outlined"
          />
        </InputMask>
      </Grid>
    </Grid>
  )
}

InputGroup.propTypes = {
  state: PropTypes.shape({
    index: PropTypes.number,
    classes: PropTypes.object,
    data: PropTypes.object,
  }),
  action: PropTypes.shape({
    handleChange: PropTypes.func,
  }),
}

export default function View({
  state: { partnersList },
  actions: { handleChange, addMorePartner, removeLastPartner },
}) {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Typography className={classes.title} variant="h6" gutterBottom>
          Passo 3: <b>Identifique os Sócios</b>
        </Typography>
        <Grid item xs={12} sm={12}>
          {partnersList.map((el, index) => (
            <Box key={index}>
              <InputGroup
                state={{ index, classes, data: el }}
                action={{ handleChange }}
              />
            </Box>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {partnersList.length > 1 && (
            <Button
              variant="outlined"
              className={clsx(classes.button, "remove")}
              onClick={removeLastPartner}
            >
              Remover
            </Button>
          )}
          <Button
            variant="outlined"
            className={clsx(classes.button, "add-more")}
            onClick={addMorePartner}
          >
            Adicionar Mais
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    partnersList: PropTypes.array,
  }),
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
    addMorePartner: PropTypes.func,
    removeLastPartner: PropTypes.func,
  }),
}
