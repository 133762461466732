export function save(key, data) {
  localStorage.setItem(key, data)
}

export function get(key) {
  return localStorage.getItem(key)
}

export function remove(key) {
  return localStorage.removeItem(key)
}

// '@nome-do-app/chave'
