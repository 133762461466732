import React, { useEffect } from "react"
import {
  createWithdraw,
  getBankAccount,
  FetchWithdrawal,
} from "~/services/withdrawal"
import View from "./View"
import { useAppDispatch } from "~/context"
import { SET_ALERT } from "~/constants"
import LoadingPage from "~/shared/components/LoadingPage"
import PropTypes from "prop-types"
import useAlert from "~/hooks/useAlert"

export default function CreateNewWithdraw({
  state: { open, page, count },
  action: { handleClose },
}) {
  const dispatch = useAppDispatch()
  const {triggerAlert} = useAlert()
  const [newWithdraw, setNewWithdraw] = React.useState({
    // payment_platform: "",
    amount: 0,
  })
  const [bankAccount, setBankAccount] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(true)

  useEffect(() => {
    async function getBank() {
      try {
        const response = await getBankAccount()
        const {
          data: { data },
        } = response
        setBankAccount(data)
      } catch (error) {
        triggerAlert({message: 'Houve um problema ao carregar as informações necessárias. Tenta por favor mais tarde!',variant: 'error'})
      } finally {
        setIsFetching(false)
      }
    }
    getBank()
  }, [])

  const handleSubmit = async () => {
    try {
      await createWithdraw(newWithdraw)
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err
      handleClose(false)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message:
            error.gateway_error ||
            error.payment_platform ||
            error.valor ||
            error.amount,
          variant: "error",
        },
      })
    }

    await FetchWithdrawal(dispatch, page, count)

    handleClose(false)

    return dispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: "Saque realizado",
        variant: "success",
      },
    })
  }
  const handleChange = (name, value) => {
    const auxNewWithdraw = newWithdraw

    auxNewWithdraw[name] = value

    return setNewWithdraw(auxNewWithdraw)
  }

  if (isFetching) return <LoadingPage />

  if(!bankAccount.length) return null;
  return (
    <View
      state={{ open, newWithdraw, bankAccount }}
      actions={{ handleClose, handleSubmit, handleChange }}
    />
  )
}

CreateNewWithdraw.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.any,
    page: PropTypes.any,
    count: PropTypes.any,
  }),
  action: PropTypes.shape({
    handleClose: PropTypes.func,
  }),
}
