import { createTheme } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#0094FF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#d2d2d2",
      contrastText: "#333",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          fontFamily: "Roboto",
        },
        body: {
          backgroundColor: "white",
        },
        hr: {
          border: "1px solid #ecebeb",
        },
      },
    },
    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover:hover": {
          backgroundColor: "rgba(1, 1, 1, 0.1)"
        }
      }
    },
    MuiTypography:{
      h4:{
        fontSize: "1.5rem"
      }
    }
  },
})

export default theme
