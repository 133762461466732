import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid } from "@material-ui/core"
import { ReactComponent as WithdrawalIcon } from "~/assets/images/withdrawal_icon.svg"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import clsx from "clsx"
import CurrencyFormat from "react-currency-format"
import bankList from "~/shared/bankList"

const useStyles = makeStyles(() => ({
  root: {
    margin: "10px 0px",
  },
  tableContainer: {
    background: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    height: "64px",
    display: "flex",
    alignItems: "center",
  },
  content: {
    // background: "red",
    margin: "2px",

    fontSize: "14px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0px",
    textAlign: "center",
    "& p": {
      fontSize: "16px",
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0px",
      color: "#A0A4A8",
    },

    "& svg": {
      fontSize: "16px",
    },
    "& .MuiIconButton-root": {
      padding: "0px !important",
      marginLeft: "10px",
    },
    "& .status": {
      margin: "auto 20px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    "& .success": {
      color: "#1BC5BD",
    },
    "& .fail": {
      color: "#F64E60",
    },
    "& .pending": {
      color: "#F6C209",
    },
    "& .processing": {
      color: "#0094FF",
    },
  },
  folder: {
    marginLeft: "25px",
    display: "flex",
    borderRadius: 8,
    width: 53,
    height: 50,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      // margin: "9px",
      fontSize: "30px",
      color: "#4C6FFF",
    },
  },
  settings: {
    justifyContent: "flex-end",
    display: "flex",
  },
}))

export function Card({ state: { item } }) {
  const classes = useStyles()
  const getStatus = {
    pending_transfer: {
      style: "pending",
      value: "Pendente",
    },
    transferred: {
      style: "success",
      value: "Realizado",
    },
    failed: {
      style: "fail",
      value: "Falhou",
    },
    canceled: {
      style: "fail",
      value: "Cancelado",
    },
    processing: {
      style: "processing",
      value: "Processando",
    },
  }
  const getBankData = (bankAccount) => {
    const findBank = bankList.find((el) => el.value === bankAccount.bank_code)

    return `${findBank.label} | Ag: ${bankAccount.agency_number} | Conta: ${bankAccount.account_number}-${bankAccount.account_digit}`
  }

  return (
    <Box my={2}>
      <Grid container className={classes.tableContainer}>
        <Grid item xs={1} sm={1}>
          <Box className={classes.folder}>
            <WithdrawalIcon />
          </Box>
        </Grid>

        <Grid item xs={2} sm={2}>
          <Box className={classes.content}>
            <p>Data de Solicitação</p>
            {/* 14/03/2021 14:37 */}
            {new Date(item.funding_estimated_date).toLocaleDateString(
              "pt-BR"
            )}{" "}
            {new Date(item.funding_estimated_date).toLocaleTimeString("pt-BR")}
          </Box>
        </Grid>

        <Grid item xs={2} sm={2}>
          <Box className={classes.content}>
            <p>Valor</p>
            <b>
              <CurrencyFormat
                value={item.amount / 100}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"R$ "}
              />
            </b>
          </Box>
        </Grid>

        <Grid item xs={5} sm={5}>
          <Box
            className={classes.content}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            alignContent="center"
          >
            <p>Conta Destino</p>
            {getBankData(item.bank_account)}
          </Box>
        </Grid>

        <Grid item xs={1} sm={1}>
          <Box className={classes.content}>
            <p>Status</p>
            <Box className={clsx("status", getStatus[item.state].style)}>
              <FiberManualRecordIcon /> {getStatus[item.state].value}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

Card.propTypes = {
  state: PropTypes.shape({
    item: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleSearch: PropTypes.func,
  }),
}
