import React, { useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import View from "./View"
import { useAppDispatch } from "~/context"
import { SET_ALERT } from "~/constants"
import { recover } from "~/services/auth"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Recover() {
  let history = useHistory()
  const { token } = useParams()
  const email = useQuery().get("email") || ""
  const dispatch = useAppDispatch()
  const [credential, setCredential] = useState({
    password: "",
    password_confirmation: "",
  })

  function handleChange(e) {
    const { value, name } = e.target

    setCredential({
      ...credential,
      [name]: value,
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (credential.password !== credential.password_confirmation)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: "As senhas não coincidem!",
          variant: "error",
        },
      })

    try {
      await recover(token, email, credential)
      history.push(`/auth`)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: "Sua senha foi redefinida com sucesso.",
          variant: "success",
        },
      })
    } catch (error) {
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: "Houve um problema. Tenta por favor mais tarde!",
          variant: "error",
        },
      })
    }
  }

  return (
    <View state={{ ...credential }} actions={{ handleSubmit, handleChange }} />
  )
}

export default {
  routeProps: {
    path: "/recover/:token",
    exact: false,
    type: "guest",
    component: Recover,
  },
  name: "Recover",
}
