// Flexible Compound Components with context
// http://localhost:3000/isolated/final/03.js

import PropTypes from 'prop-types'
import * as React from 'react'
import { Switch } from './switch'
import { useToggle } from './ToggleHook'

function Toggle({ initValue, onClick }) {
  const { on, getTogglerProps } = useToggle({ initValue })
  return (
    <div>
      <Switch
        {...getTogglerProps({
          'aria-label': 'custom-button',
          onClick: onClick,
          id: 'custom-button-id',
          on
        })}
      />
    </div>
  )
}

Toggle.propTypes = {
  onClick: PropTypes.func,
  initValue: PropTypes.bool
}

export default Toggle
