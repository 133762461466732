import React from "react"
import PropTypes from "prop-types"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

import modules from "./modules"
import { useAppState } from "~/context"
import { USER_STATUS } from "./constants"

function Routes() {
  return (
    <Router>
      <Switch>
        {modules.map((module) => (
          <AuthRoute key={module.name} {...module.routeProps} />
        ))}
        <Route path="*">
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        </Route>
      </Switch>
    </Router>
  )
}

function AuthRoute({ type, path, ...rest }) {
  const { user } = useAppState()
  const isActive = [USER_STATUS.ACTIVE, USER_STATUS.ANALYZING].includes(user.data?.aff_status)
  if (user.data) {
    if (path === "/auth" && isActive) {
      return <Redirect to="/" />
    }
    else if (
      type === "private" &&
      path !== "/register" &&
      !isActive
    ) {
      return <Redirect to="/register" />
    }
  } else if (type === "private"){
    return <Redirect to="/auth" />
  }

  return <Route {...rest} />
}

AuthRoute.propTypes = {
  type: PropTypes.string,
  path: PropTypes.string,
}

export default Routes;
