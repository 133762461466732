import React, { useEffect, useState } from "react"
import { useAppState } from "~/context"
import View from "./View"

export default function Review() {
  const { register } = useAppState()
  const [availableBank, setAvailableBank] = useState([])

  useEffect(() => {
    setAvailableBank(register.bankList)
  }, [])

  return (
    <View
      state={{
        data: {
          ...register.data,
        },
        availableBank,
      }}
    />
  )
}
