export const SET_USER = "SET_USER"
export const LOADING = "LOADING"
export const SET_ALERT = "SET_ALERT"
export const SET_INFO = "SET_INFO"
export const SET_REGISTER = "SET_REGISTER"
export const SET_WITHDRAWAL = "SET_WITHDRAWAL"
export const USER_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ANALYZING: 'analyzing',
}
export const ROLES = {
  FREE: 'free'
}

export const SET_LANG = {
  LANGS: {
    EN: "EN",
    PT_BR: "pt_BR",
  },
}

export const ASYNC_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
}

export const SETTINGS = {
  use_affiliate_link_in_mf_tag: 'use_affiliate_link_in_mf_tag'
}