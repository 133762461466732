import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  InputLabel,
} from "@material-ui/core"
import React from "react"
import PropTypes from "prop-types"
import CloseIcon from "@material-ui/icons/Close"
import { ReactComponent as InfoIcon } from "~/assets/images/info_modal_icon.svg"
import CurrencyFormat from "react-currency-format"
import clsx from "clsx"
import { useAppState } from "~/context"

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(3),
    borderBottom: "2px solid #E5E9F5",
    display: "flex",
    "& p": {
      textAlign: "left",
      fontSize: "28px",
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: 700,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0px 50px",
    height: "100%",

    overflow: "hidden",

    "& p": {
      textAlign: "left",
      fontSize: "30px",
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: 400,
      margin: "10px 0px 0px",
    },
  },
  content2: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 70px",
    height: "100%",
    background: "#F5F7FF",
  },
  selectType: {
    // padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    textAlign: "left",
    fontSize: "20px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 700,

    width: "100%",

    "& div": {
      borderRadius: "8px",
    },
    "&:nth-child(2)": {
      borderRadius: "50px !important",
    },
  },
  label: {
    textAlign: "left",
    fontSize: "18px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 800,
    color: "#222",

    margin: "30px 0px 15px",
  },
  input: {
    "& fieldset": {
      borderRadius: 8,
      border: "2px solid #EDF0F7",
    },
    "&:hover": {
      border: "none",
    },
    "& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "& label": {
      color: "#52575C",
      opacity: 0.5,
      fontFamily: "Nunito Sans",
    },
  },
  info: {
    background: "#E5E9F5",
    width: "100%",
    padding: "15px 70px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",

    "& p": {
      margin: "0px 50px",
      color: "#0094FF",
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "14px",
    },
    "& svg": {
      width: "140px",
      height: "auto",
    },
  },
  btn: {
    width: "180px",
    height: "45px",
    borderRadius: "8px",
    "&.done, &.done:hover": {
      background: "#4C6FFF",
      color: "#fff",
    },
    "&.done.disabled, &.done.disabled:hover": {
      opacity: 0.4,
    },
    "&.cancel, &.cancel:hover": {
      border: "2px solid #4C6FFF",
      color: "#4C6FFF",
    },
  },
  adorment: {
    "& .MuiTypography-paragraph": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#EDF0F7",
      marginLeft: "-14px !important",
      marginRight: "28px",
      height: "56px",
      width: "100px",
      border: "2px solid #F5F7FF",
      borderRadius: "8px 0px 0px 8px",
      color: "#52575C",
      fontSize: "20px",
      opacity: 0.5,
      fontWeight: 600,
      fontFamily: "Nunito Sans",
    },
    "& p": {
      margin: 0,
      padding: 0,
    },
  },
  item: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "25282B",
    opacity: 0.5,
    fontSize: 14,
  },
}))

export default function View({
  state: { open, bankAccount },
  actions: { handleClose, handleSubmit, handleChange },
}) {
  const classes = useStyles()
  const { info } = useAppState()

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle className={classes.title}>
        <Typography component="p">Novo Saque</Typography>
        <IconButton
          onClick={() => handleClose(false)}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Typography component="p">
          Saldo disponível para saque:
          <br />
          <b>
            <CurrencyFormat
              value={info.data.available ? info.data.available / 100 : 0}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"R$ "}
            />
          </b>
        </Typography>

        <Box htmlFor="bank_account_select" className={classes.label}>
          Conta Bancária
          <Box className={classes.item}>
            {bankAccount[0].owner_name} | Ag: {bankAccount[0].agency_number} |
            C: {bankAccount[0].account_number} - {bankAccount[0].account_digit}
          </Box>
        </Box>

        <Box className={classes.selectType}>
          <InputLabel htmlFor="bank_account_select" className={classes.label}>
            Valor do Saque
          </InputLabel>
          <CurrencyFormat
            InputProps={{
              startAdornment: (
                <Box className={classes.adorment} position="start">
                  <Typography paragraph>R$</Typography>
                </Box>
              ),
            }}
            id="amount"
            name="amount"
            placeholder="0,00"
            className={classes.input}
            variant="outlined"
            fullWidth
            autoComplete="off"
            customInput={(props) => <TextField {...props} />}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            onValueChange={(values) => {
              const { floatValue } = values
              handleChange("amount", floatValue * 100)
            }}
          />
          <Box style={{ opacity: 0.5, fontSize: 14, fontWeight: 400 }}>
            * Valor mínimo permitido para saque: R$ 10,00
          </Box>
        </Box>
      </DialogContent>
      <Box className={classes.info}>
        <InfoIcon />
        <Typography variant="body2" component="p">
          <b>Nota:</b>
          <br />É cobrado uma taxa de R$3,67 por saque. Logo, o valor final
          enviado para sua conta será decrementado deste valor. Saques podem
          durar até 2 dias úteis para serem efetivados.
        </Typography>
      </Box>

      <DialogActions className={classes.content2}>
        <Box
          className={classes.selectType}
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: '16px'
          }}
        >
          <Button
            className={clsx(classes.btn, "cancel")}
            onClick={() => handleClose(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            disabled={!info.data.available}
            className={clsx(
              classes.btn,
              "done",
              !info.data.available && "disabled"
            )}
            onClick={handleSubmit}
            color="primary"
          >
            Efetuar Saque
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.any,
    newWithdraw: PropTypes.any,
    bankAccount: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
  }),
}
