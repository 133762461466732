import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../styles"
import { Container, Box } from "@material-ui/core"
import clsx from "clsx"

export default function ReviewContainer({ state: { data, availableBank } }) {
  const classes = useStyles()

  const findBankData = (bank) => {
    const bankLabel = availableBank.find((el) => el.code === bank)
    return bankLabel ? bankLabel.name : ""
  }
  const hasPartners =
    data.bank_account.document_type === "corporation" && data.partners.length

  return (
    <Container className={classes.root}>
      <Typography className={classes.title} variant="h6" gutterBottom>
        Passo 3: <b>Revisão dos Dados</b>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography
            className={classes.categoryTitle}
            variant="h6"
            gutterBottom
          >
            Dados Bancários
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.stepGroup}>
          <Grid item xs={12} sm={12} className={clsx(classes.info)}>
            <Typography paragraph>
              <b>Nome do Favorecido: </b>
              {data.bank_account.owner_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={clsx(classes.info)}>
            <Typography paragraph>
              <b>Banco: </b>
              {findBankData(data.bank_account.bank_code)} | AG:{" "}
              {data.bank_account.agency_number} | C:{" "}
              {data.bank_account.account_number}-
              {data.bank_account.account_digit}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={clsx(classes.info)}>
            <Typography paragraph>
              <b>CPF ou CNPJ: </b>
              {data.bank_account.document_number}
            </Typography>
          </Grid>
        </Grid>

        {hasPartners && data.partners.length ? (
          <>
            <Grid item xs={12} sm={12}>
              <Typography
                className={classes.categoryTitle}
                variant="h6"
                gutterBottom
              >
                Identifique os Sócios
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {data.partners.map((el, index) => (
                <Box
                  key={index}
                  style={{
                    borderBottom:
                      data.partners.length > 1 ? "1px solid #52575C" : "none",
                    margin: "15px 0px",
                  }}
                >
                  <Typography
                    className={classes.categoryTitle}
                    variant="h6"
                    gutterBottom
                  >
                    Sócio {index + 1}
                  </Typography>

                  <Box>
                    <Box className={clsx(classes.info)}>
                      <Typography paragraph>
                        <b>Nome: </b>
                        {el.name}
                      </Typography>
                    </Box>
                    <Box className={clsx(classes.info)}>
                      <Typography paragraph>
                        <b>Email: </b>
                        {el.email}
                      </Typography>
                    </Box>
                    <Box className={clsx(classes.info)}>
                      <Typography paragraph>
                        <b>CPF: </b>
                        {el.document_number}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Grid>
          </>
        ) : null}
      </Grid>
    </Container>
  )
}

ReviewContainer.propTypes = {
  state: PropTypes.shape({
    data: PropTypes.any,
    availableBank: PropTypes.array,
  }),
}
