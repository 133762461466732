import React from "react"
import Box from "@material-ui/core/Box"
import { InputAdornment, TextField } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

const useStyles = makeStyles(() => ({
  search: {
    height: 40,
    background: "#FFFFFF",
    borderRadius: 50,
    "& fieldset": {
      height: 45,
      borderRadius: 50,
      border: "2px solid #EDF0F7",
      boxSizing: "border-box",
    },
    "& div": {
      height: 40,
    },
    "& svg": {
      color: "#A9ABAE",
    },
  },
}))

export default function Search({ value, handleSearch }) {
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.search}>
        <TextField
          variant="outlined"
          fullWidth
          id="search"
          name="search"
          placeholder="Pesquisar"
          onChange={handleSearch}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

Search.propTypes = {
  value: PropTypes.any,
  handleSearch: PropTypes.func,
}
