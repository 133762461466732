import React from "react"
import View from "./View"

function Links() {
  

  return (
    <View
      // state={{
      //   anchorEl
      // }}
      // actions={{
      //   handleMenu,
      //   handleClose,
      // }}
    />
  )
}

export default Links
