import React, { useState } from "react"
import View from "./View"
import { useAppDispatch } from "~/context"
import { SET_ALERT } from "~/constants"
import { resetPassword } from "~/services/auth"

function ForgotPassword() {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState("")
  const [messageState, setMessageState] = useState("")

  function handleChange(e) {
    const { value } = e.target

    setEmail(value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!email.length)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: "Campo Email é obrigatório",
          variant: "error",
        },
      })

    try {
      await resetPassword({ email })
    } catch (err) {
      return setMessageState("error")
    }

    return setMessageState("success")
  }
  return (
    <View
      state={{ email, messageState }}
      actions={{ handleSubmit, handleChange }}
    />
  )
}

export default {
  routeProps: {
    path: "/forgotpassword",
    exact: true,
    type: "guest",
    component: ForgotPassword,
  },
  name: "ForgotPassword",
}
