import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
// import makefunnelsLogo from "~/assets/images/makefunnels-logo.png"
import { ReactComponent as Logo } from "~/assets/images/logo-y.svg"
import Copyright from "./components/Copyright"
import BackgroundAnimation from "./components/BackgroundAnimation"
import { useStyles } from "./styles"
import { Link } from "@material-ui/core"
import { useHistory } from "react-router-dom"

function View({
  email,
  password,
  loading,
  actions: { handleChange, handleSubmit },
}) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <BackgroundAnimation />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h3" className={classes.title}>
          MakeFunnels
        </Typography>
        <CssBaseline />
        <div className={classes.paper}>
          <Logo className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Painel de Afiliados
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              value={email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={password}
            />
            <Box
              style={{
                textAlign: "right",
              }}
            >
              <Link
                onClick={() => history.push("/forgotpassword")}
                color="inherit"
                style={{
                  cursor: "pointer",
                }}
              >
                Esqueci minha senha
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={loading}
            >
              Entrar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}

View.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  loading: PropTypes.bool,
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}

View.defaultProps = {
  email: "",
  password: "PropTypes.string",
  loading: false,
  actions: {
    handleChange: () => {},
    handleSubmit: () => {},
  },
}

export default View
