// Prop Collections and Getters
// 💯 prop getters
// http://localhost:3000/isolated/final/04.extra-1.js

import * as React from 'react'

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn?.(...args))

function useToggle({ initValue }) {
  const [on, setOn] = React.useState(initValue || false)
  const toggle = () => setOn(!on)

  /**
   * Returns props for input.
   *
   * @param {function} onClick The result of this function trigger the toggle function, so this function should return boolean.
   * @param {object} props Rest of props.
   */
  function getTogglerProps({ onClick, ...props } = {}) {
    const fn = (...args) => {
      if (typeof onClick !== 'function') {
        throw new Error('You should pass onClick as a function for getTogglerProps')
      }
      return onClick?.(...args) && toggle()
    }

    return {
      'aria-pressed': on,
      onClick: callAll(fn),
      ...props,
    }
  }

  return {
    on,
    toggle,
    getTogglerProps,
  }
}

export { useToggle }
