import React from "react"
import Form from "./components/Form"

function RegisterContainer() {
  return (
    <div
      style={{
        backgroundColor: "#F5F7FF",
        height: "100vh",
        // marginTop: "-40px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Form />
    </div>
  )
}

export default RegisterContainer
