import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
// import { ProgressBar } from "react-milestone"
import { Typography } from "@material-ui/core"
import { getProgress } from "~/services/affiliates"
import { ProgressBar, Step } from "react-step-progress-bar"
import "react-step-progress-bar/styles.css"
import LoadingPage from "~/shared/components/LoadingPage"

const useStylesRenderMilestone = makeStyles(() => ({
  target: (props) => ({
    backgroundColor: `${props.completed ? "#1BC5BD" : "lightgray"}`,
    background: "#1BC5BD",
    color: "white",
    borderRadius: "50%",
    width: !props.isFinal ? "30px" : "40px",
    height: !props.isFinal ? "30px" : "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  milestoneStyle: {
    marginTop: "-25px",
    textAlign: "center",
  },
}))

const RenderMilestone = ({ data, index, isCompleted }) => {
  const classes = useStylesRenderMilestone({
    completed: isCompleted,
    isFinal: index === data.length - 1,
  })
  return (
    <div className={classes.milestoneStyle}>
      <Typography>
        <b>{parseFloat(data[index].value) * 100}%</b>
      </Typography>
      <div className={classes.target}>
        <Typography variant="body2">
          {index === data.length - 1 ? data[index].to : data[index].from}
        </Typography>
      </div>
    </div>
  )
}
RenderMilestone.propTypes = {
  index: PropTypes.number,
  data: PropTypes.any,
  isCompleted: PropTypes.bool,
}

export default function Bar() {
  // const classes = useStyles()

  const [percentage, setPercentage] = React.useState({
    progress: 0,
    levels: 3,
  })

  useEffect(() => {
    async function progress() {
      const response = await getProgress()
      const {
        data: { general_progress, levels },
      } = response
      setPercentage({
        progress: general_progress,
        levels: levels,
      })
    }
    progress()
  }, [])

  if (typeof percentage.levels !== typeof []) return <LoadingPage />

  return (
    <React.Fragment>
      <ProgressBar
        percent={parseFloat(percentage.progress)}
        filledBackground="#1BC5BD"
        unfilledBackground="lightgrey"
        stepPositions={[1, 4, 31, 100]}
        height={7}
        style={{ position: "relative" }}
      >
        <Step transition="scale">
          {({ accomplished, index }) => {
            return (
              <RenderMilestone
                index={index}
                data={percentage.levels}
                isCompleted={accomplished}
              />
            )
          }}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => {
            return (
              <RenderMilestone
                index={index}
                data={percentage.levels}
                isCompleted={accomplished}
              />
            )
          }}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => {
            return (
              <RenderMilestone
                index={index}
                data={percentage.levels}
                isCompleted={accomplished}
              />
            )
          }}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => {
            return (
              <RenderMilestone
                index={index}
                data={percentage.levels}
                isCompleted={accomplished}
              />
            )
          }}
        </Step>
      </ProgressBar>
    </React.Fragment>
  )
}

Bar.propTypes = {}
