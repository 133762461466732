import { SET_ALERT } from '~/constants';
import { useAppDispatch } from '~/context';

const useAlert = () => {
  const dispatch = useAppDispatch()
  function triggerAlert({open = true, message = '', variant = 'success'}) {
      dispatch({
            type: SET_ALERT,
            payload: {
              open,
              message,
              variant,
            },
          })
  }

  return {
    triggerAlert
  }
  
}

export default useAlert;