import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons/"
import { Typography, Container, Box, Tooltip } from "@material-ui/core"
import LinksList from "./components/LinksList"
import Search from "~/components/Search"
import { getReferralLinks } from "~/services/affiliates"
import { useResponsive } from "~/hooks"

const useStyles = makeStyles((theme) => ({
  title: {
    marginRight: theme.spacing(1),
    fontWeight: 700,
    fontFamily: "Nunito Sans",
  },
  helperOutline: {
    color: theme.palette.secondary.main,
  },
}))

export default function View() {
  const classes = useStyles()
  const [linksList, setLinks] = React.useState([])
  const [searchValue, setSearchValue] = React.useState([])
  const [linksListFilter, setLinksFilter] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(true)
  const { isMobile } = useResponsive()

  useEffect(() => {
    async function getLinks() {
      try {
        const {
          data: { data },
        } = await getReferralLinks()

        setLinks(data)
        setLinksFilter(data)
        setIsFetching(false)
      } catch (error) {
        setIsFetching(false)
      }
    }

    getLinks()
  }, [])

  const handleSearch = (event) => {
    const { value } = event.target
    const auxList = linksList.filter(
      (el) => el.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
    setLinksFilter(auxList)
    setSearchValue(value)
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" my={4} flexDirection={isMobile ? 'column' : 'row'} align-items="flex-start">
        <Box display="flex" alignItems="center">
          <Typography variant="h6" className={classes.title}>
            Seus Links Únicos de Afiliado
          </Typography>
          <Tooltip title="Utilize os links abaixo para divulgar a MakeFunnels. É por meio desses links que identificaremos que a venda de uma nova assinatura veio por sua indicação.">
            <HelpOutline fontSize="small" className={classes.helperOutline} />
          </Tooltip>
        </Box>

          <Search value={searchValue} handleSearch={handleSearch} />
      </Box>

      <Box mt={4}>
        <LinksList state={{ linksList: linksListFilter, isFetching }} />
      </Box>
    </Container>
  )
}

View.propTypes = {
  //   status_values,
  //   balance_values
}
