import React from "react"
import Button from "@material-ui/core/Button"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  loadMore: {
    borderRadius: "8px !important",
    color: "#4C6FFF",
    border: "2px solid #4C6FFF",
    backgroundColor: "#4C6FFF00",
    fontFamily: "Nunito Sans",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
      border: "2px solid #4C6FFF",
      backgroundColor: "#4C6FFF00",
    },
  },
  newWithdrawal: {
    borderRadius: "8px !important",
    color: "#FFF",
    border: "2px solid #4C6FFF",
    backgroundColor: "#4C6FFF",
    fontFamily: "Nunito Sans",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
      border: "2px solid #4C6FFF",
      backgroundColor: "#4C6FFF",
    },
  },
}))

export default function ButtonWithdraw({
  state: { variant, style, text, icon },
  action: { handleClick },
}) {
  const classes = useStyles()
  return (
    <Button
      variant={variant}
      color="secondary"
      className={classes[style]}
      onClick={handleClick}
      startIcon={icon}
    >
      {text}
    </Button>
  )
}

ButtonWithdraw.propTypes = {
  state: PropTypes.shape({
    variant: PropTypes.string,
    style: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.any,
  }),
  action: PropTypes.shape({
    handleClick: PropTypes.func,
  }),
}
