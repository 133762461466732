import axios from "axios"
import * as localStorage from "../../utils/localStorage"

const axiosNewInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const token = localStorage.get("@makeFunnels_affiliate/token")

axiosNewInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`

export default axiosNewInstance
