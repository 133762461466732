import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Box, Container } from "@material-ui/core"
import clsx from "clsx"
import BankAccount from "../BankAccount"
import { ReactComponent as Logo } from "../../../../assets/images/logo.svg"
import Review from "../Review"
import Partners from "../Partners"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px",
    height: "100vh",
  },
  layout: {
    width: 700,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    // minHeight: 780,
    borderRadius: "5px",
    boxShadow:
      "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 4px rgba(96, 97, 112, 0.16)",
  },
  stepper: {
    marginTop: theme.spacing(2),
    background: "#F5F7FF",
    fontFamily: "Nunito Sans",
    "& svg": {
      color: "#c3c3c3cc",
    },
    "& .MuiStepIcon-active": {
      color: "#4C6FFF",
    },
  },
  buttons: {
    margin: "25px 24px 24px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    borderRadius: "8px",
    fontFamily: "Nunito Sans",
    fontWeight: 800,
    "&.back": {
      color: "#4C6FFF",
      border: "2px solid #4C6FFF",
      width: 150,
      borderRadius: 8,
    },
    "&.go": {
      backgroundColor: "#4C6FFF",
      width: 150,
      borderRadius: 8,
    },
    "&.go.disabled": {
      backgroundColor: "#C5C5C5",
      color: "#F9F9F9",
    },
    "&.finish": {
      width: "100%",
      backgroundColor: "#3ACF6C",
    },
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  divider: {
    width: "5px",
    height: "40px",
    background: "#4C6FFF",
    borderRadius: "8px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& h1": {
      marginLeft: 10,
      fontWeight: 800,
    },
  },

  titleAffiliates: {
    borderLeft: "2px solid lightgray",
    paddingLeft: "8px",
    fontFamily: "Nunito Sans",
    fontWeight: 800,
    fontSize: 16,
    color: "#52575C",
  },
  logo: {
    marginRight: theme.spacing(1),
    maxWidth: 250
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "45px",
    marginTop: "25px",
  },
}))

function getStepContent(step, isCorporationAccount) {
  if (step === 0) return <BankAccount />
  else if (step === 1 && isCorporationAccount) return <Partners />
  else return <Review />
}

export default function Checkout({
  state: { activeStep, isCorporationAccount },
  actions: { handleNext, handleBack, handleSubmit },
}) {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Logo className={classes.logo} />
        <Typography variant="body2" className={classes.titleAffiliates}>
          Painel de Afiliados
        </Typography>
      </Box>
      <Box className={classes.layout}>
        <Box className={classes.title}>
          <div className={classes.divider}></div>
          <Typography component="h1" variant="h4" align="center">
            Finalize seu Cadastro
          </Typography>
        </Box>

        <Stepper activeStep={activeStep} className={classes.stepper}>
          <Step>
            <StepLabel>Dados Bancários</StepLabel>
          </Step>
          {isCorporationAccount && (
            <Step>
              <StepLabel>Identifique os Sócios</StepLabel>
            </Step>
          )}
          <Step>
            <StepLabel>Revisão dos Dados</StepLabel>
          </Step>
        </Stepper>

        <Paper className={classes.paper}>
          {getStepContent(activeStep, isCorporationAccount)}
          <div className={clsx(classes.buttons, activeStep !== 0 && "next")}>
            {activeStep !== 0 && (
              <Button
                variant="outlined"
                onClick={handleBack}
                className={clsx(classes.button, "back")}
              >
                Voltar
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={activeStep === 2 ? handleSubmit : handleNext}
              className={clsx(
                classes.button,
                activeStep === 2 ? "finish" : "go"
              )}
            >
              {activeStep === 2 ? "Finalizar Cadastro" : "Próximo"}
            </Button>
          </div>
        </Paper>
      </Box>
    </Container>
  )
}

Checkout.propTypes = {
  state: PropTypes.shape({
    isCorporationAccount: PropTypes.bool,
    activeStep: PropTypes.number,
  }),
  actions: PropTypes.shape({
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}
