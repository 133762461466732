import React from 'react'
import { ASYNC_STATE } from '~/constants'
import { useAsync } from '~/hooks/useAsync'
import { getSettings, updateSetting as _updateSetting } from '~/services/settings'

const SettingsContext = React.createContext({
  status: ASYNC_STATE.IDLE
})

// eslint-disable-next-line react/prop-types
function SettingsProvider({ children }) {
  const { data, status, error, run } = useAsync()

  const mounted = React.useRef(false)

  React.useLayoutEffect(() => {
    if(!mounted.current){
      run(getSettings())
    }

    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [run])

  const updateSetting = React.useCallback((setting, value) => _updateSetting({ [setting]: value }), [])
  
  const context = React.useMemo(
    () => ({
      status,
      error,
      useAffiliateLinkInMfTag: data?.data?.data?.use_affiliate_link_in_mf_tag,
      updateSetting
    }),
    [status, error, data?.data?.data?.use_affiliate_link_in_mf_tag, updateSetting]
  );

  return <SettingsContext.Provider value={context}>{children}</SettingsContext.Provider>
}

function useSettings() {
  const context = React.useContext(SettingsContext)
  if (context === undefined) {
    throw new Error('useSettingsState must be used within a settingsProvider')
  }
  return context
}

export { SettingsProvider, useSettings }
