import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import { ReactComponent as Logo } from "~/assets/images/logo-y.svg"
import "./style.css"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    maxHeight: "70px",
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    marginTop: theme.spacing(8),
    color: "white",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  info: {
    fontSize: 12,
    opacity: 0.5,
    margin: 0,
    padding: 0,
  },

  message: {
    display: "none",
    padding: 12,
    borderRadius: 4,

    "& p": {
      fontSize: 14,
      margin: 0,
      padding: 0,
    },

    "&.error": {
      color: "#ff1744",
      display: "inline",
    },
    "&.success": {
      display: "inline",
      color: "#4caf50",
    },
  },
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        MakeFunnels
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

function BackgroundAnimation() {
  return (
    <div className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default function View({
  state: { email, messageState },
  actions: { handleChange, handleSubmit },
}) {
  const classes = useStyles()

  return (
    <>
      <BackgroundAnimation />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h3" className={classes.title}>
          MakeFunnels
        </Typography>
        <CssBaseline />
        <div className={classes.paper}>
          <Logo className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Recuperação de Senha
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography paragraph className={classes.info}>
              Por favor, informe o e-mail da sua conta. Um link de redefinição
              de senha será enviado.
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="current-email"
              onChange={handleChange}
              value={email}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Recuperar senha
            </Button>
          </form>

          <Box className={clsx(classes.message, messageState)}>
            {messageState === "success" ? (
              <Typography paragraph>
                Pronto, enviamos para o seu e-mail um link para você redefinir
                sua senha. Caso não encontre o e-mail na caixa de entrada,
                verifique outras abas e spam.
              </Typography>
            ) : (
              <Typography paragraph>
                Hmm, parece que este e-mail ainda não está cadastrado no nosso
                sistema. Se acredita que digitou o e-mail correto e acha que
                essa mensagem é um engano, por favor, envie um e-mail para
                suporte@makefunnels.com.br
              </Typography>
            )}
          </Box>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string,
    messageState: PropTypes.string,
  }),
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
}
