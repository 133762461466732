import React, { useCallback } from 'react';
import { Toggle } from '~/components/Toggle';
import { ASYNC_STATE, SETTINGS } from '~/constants';
import { SettingsProvider, useSettings } from '~/context/settings-context';
import { Container, Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"

let debounce;

const AffiliatesLinkToggle = () => {
    const { status, useAffiliateLinkInMfTag, updateSetting } = useSettings()
    const isLoaded = status === ASYNC_STATE.RESOLVED
    const hasError = status === ASYNC_STATE.REJECTED

    const handleOnClick = useCallback((e) => {
        clearTimeout(debounce)
        const on = e.target.checked
        debounce = setTimeout(() => {
            updateSetting(SETTINGS.use_affiliate_link_in_mf_tag, on)
        }, 300);
        return true
    }, [updateSetting])


    if (hasError) return null
    return isLoaded && (
        <React.Fragment>
            <Box display="flex" >
                <Box mr={3}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Incluir Link de Afiliado no mini-banner “Feito Com MakeFunnels”
                    </Typography>
                </Box>
                <Toggle initValue={useAffiliateLinkInMfTag} onClick={handleOnClick} />
            </Box>
            <Box mr={3}>
                <Typography variant="subtitle1">
                Para que seu link de afiliado seja incluído, além desta opção, é necessário ativar o mini-banner “Feito Com MakeFunnels” no seu painel de usuário, em Configurações.
                </Typography>
            </Box>
        </React.Fragment>
    );
}

const AffiliatesLinkToggleWithContext = () => {
    return <SettingsProvider>
        <Container>
            <AffiliatesLinkToggle />
        </Container>
    </SettingsProvider>;
}

export default AffiliatesLinkToggleWithContext;