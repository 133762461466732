import React, { useEffect, useState } from "react"
import { useAppState } from "~/context"
import View from "./View"

function Progress() {
  const { info } = useAppState()

  const [commission, setCommission] = useState()

  useEffect(() => {
    setCommission(info.data.commission)
  }, [info.data])

  return (
    <View
      state={{
        commission,
      }}
    />
  )
}

export default Progress
