import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Menu,
  Avatar,
  Box,
} from "@material-ui/core"
import { deepPurple } from "@material-ui/core/colors"
import { ExpandMore } from "@material-ui/icons"
import { ReactComponent as Logo } from "../../../../assets/images/logo.svg"
import { useHistory } from "react-router-dom"
import { ReactComponent as WithdrawalIcon } from "~/assets/images/withdrawal_icon.svg"
import { ReactComponent as PanelIcon } from "~/assets/images/panel_icon.svg"
import { ReactComponent as ExitIcon } from "~/assets/images/exit_icon.svg"
import { ReactComponent as InfoIcon } from "~/assets/images/info_icon.svg"
import { useResponsive } from "~/hooks"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: "none !important",
    height: 72,
    justifyContent: "center",
  },
  MenuItem: {
    fontSize: "16px",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    "& svg": {
      marginRight: "10px",
    },
  },
  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    borderLeft: "2px solid lightgray",
    paddingLeft: "8px",
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "capitalize",
    fontWeight: "normal",
  },
  logo: {
    marginRight: theme.spacing(1),
    height: 30
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    marginLeft: theme.spacing(1),
  },
}))

export default function View({
  state: { anchorEl, userInfo, isPanel },
  actions: { handleMenu, handleClose, handleLogOut },
}) {
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const { push } = useHistory()
  const { isMobile } = useResponsive()

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'} onClick={() => push("/")}>
              <Logo className={classes.logo} />
              <Typography variant="body2" className={classes.title}>
                Painel de Afiliados
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              {!isMobile && (
                <React.Fragment>
                  {isPanel ? (
                    <Button
                      className={classes.button}
                      startIcon={<WithdrawalIcon />}
                      onClick={() => push("/saque")}
                    >
                      Realizar Saque
                    </Button>
                  ) : (
                    <Button
                      className={classes.button}
                      startIcon={<PanelIcon />}
                      onClick={() => push("/")}
                    >
                      Meu Painel
                    </Button>
                  )}
                </React.Fragment>
              )}
              <Box display="flex" justifyContent="center" alignItems="center">
                {!isMobile && (
                  <Typography variant="body1">
                    Olá, <strong>{userInfo.name}</strong>
                  </Typography>
                )}
                <Avatar className={classes.purple}>{userInfo.name[0]}</Avatar>
              </Box>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <ExpandMore />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disabled
                  className={classes.MenuItem}
                  onClick={() => push("/account")}
                >
                  <InfoIcon />
                  Ajuda
                </MenuItem>
                <MenuItem className={classes.MenuItem} onClick={handleLogOut}>
                  <ExitIcon />
                  Sair
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    anchorEl: PropTypes.any,
    isPanel: PropTypes.bool,
    userInfo: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleMenu: PropTypes.func,
    handleClose: PropTypes.func,
    handleLogOut: PropTypes.func,
  }),
}
