import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  title: {
    color: "#52575C",
    margin: "15px 0px",
    fontFamily: "Nunito Sans",
  },
  categoryTitle: {
    color: "#52575C",
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    padding: 0,
    marginBottom: "-15px",
  },
  stepGroup: {
    marginTop: "-25px",
    marginBottom: "8px",
  },
  info: {
    marginTop: 15,
    "& p": {
      marginBottom: "8px",
      color: "#52575C",
      fontFamily: "Nunito Sans",
    },
  },
  root: {
    // minHeight: 580,
  },
  input: {
    "& fieldset": {
      borderRadius: 8,
      border: "2px solid #EDF0F7",
    },
    "&:hover": {
      border: "none",
    },
    "& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "& label": {
      color: "#52575C",
      opacity: 0.5,
      fontFamily: "Nunito Sans",
    },
  },
  button: {
    fontFamily: "Nunito Sans",
    fontWeight: 700,
    "&.remove": {
      marginRight: 20,
      borderRadius: 8,
      border: "2px solid #A0A4A8",
      color: "#A0A4A8",
    },
    "&.add-more": {
      borderRadius: 8,
      border: "2px solid #4C6FFF",
      color: "#4C6FFF",
    },
  },
}))

export { useStyles }
