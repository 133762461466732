import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: "10px",
  },
  avatar: {
    marginBottom: theme.spacing(2),
    maxHeight: "70px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#424242",
    "&:hover": {
      backgroundColor: "#666565",
    },
  },
  title: {
    marginTop: theme.spacing(8),
    color: "white",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
}))
