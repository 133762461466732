import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { SET_ALERT, SET_WITHDRAWAL } from "~/constants"

import { useAppDispatch, useAppState } from "~/context"
import { getInfo } from "~/services/withdrawal"
import LoadingPage from "~/shared/components/LoadingPage"
import View from "./View"

export default function List() {
  const dispatch = useAppDispatch()
  const { withdrawal } = useAppState()
  const [page, setPage] = React.useState(1)
  const [list, setList] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const { push } = useHistory()

  useEffect(() => {
    async function getWithdrawal() {
      try {
        const {
          data: { data },
        } = await getInfo(page, 6)

        setIsFetching(withdrawal.isFetching)

        dispatch({
          type: SET_WITHDRAWAL,
          payload: { data, isFetching: false },
        })
        // setList(data)
      } catch (error) {
        dispatch({
          type: SET_ALERT,
          payload: {
            open: true,
            message:
              "Houve um problema ao listar seus saques! Tente novamente mais tarde",
            variant: "error",
          },
        })
        push("/")
        setIsFetching(true)
      }
    }

    getWithdrawal()
  }, [dispatch, withdrawal.isFetching, page, push])

  useEffect(() => {
    setList(withdrawal.data)
  }, [dispatch, withdrawal.data])

  if (isFetching) return <LoadingPage />
  return <View state={{ list, open, page }} actions={{ setOpen, setPage }} />
}
