import React, { useEffect } from "react"
import View from "./View"
import { useAppDispatch, useAppState } from "~/context"
import LoadingPage from "~/shared/components/LoadingPage"
import { logout } from "~/services/auth"
import { SET_USER } from "~/constants"
import * as localStorage from "~/utils/localStorage"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"

function AppBar({ isPanel }) {
  const { user } = useAppState()

  const history = useHistory()

  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [userInfo, setUserInfo] = React.useState(null)

  useEffect(() => {
    setUserInfo(user.data)
  }, [user])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    dispatch({ type: SET_USER, payload: null })
    localStorage.remove("@makeFunnels_affiliate/token")
    logout()
    history.push("/auth")
  }

  if (!userInfo) return <LoadingPage />

  return (
    <View
      state={{
        anchorEl,
        userInfo,
        isPanel,
      }}
      actions={{
        handleMenu,
        handleClose,
        handleLogOut,
      }}
    />
  )
}

AppBar.propTypes = {
  isPanel: PropTypes.bool,
}

export default AppBar
