import React from "react"
import AppBar from "./components/AppBar"
import Overview from "./components/Overview"
import Progress from "./components/Progress"
import Links from "./components/Links"
import AffiliatesLinkToggle from "./components/AffiliatesLinkToggle"

function Dashboard() {
  return (
    <div>
      <AppBar isPanel={true} />
      <Overview />
      <Progress />
      <AffiliatesLinkToggle />
      <Links />
    </div>
  )
}

export default Dashboard
