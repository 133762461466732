import React, { useEffect, useState } from "react"
import { SET_REGISTER } from "~/constants"
import { useAppDispatch, useAppState } from "~/context"
import { getAvailableBanks } from "~/services/auth"
import View from "./View"

export default function BankAccount() {
  const { register } = useAppState()
  const dispatch = useAppDispatch()
  const [availableBank, setAvailableBank] = useState([])

  useEffect(() => {
    async function getBankList() {
      try {
        const { data } = await getAvailableBanks()
        setAvailableBank([{ code: "", name: "Selecione um banco" }, ...data])
        dispatch({
          type: SET_REGISTER,
          payload: {
            data: { ...register.data },
            bankList: data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    }

    getBankList()
  }, [])

  const handleChange = (name, value) => {
    const newData = register.data
    newData.bank_account[name] = value
    dispatch({
      type: SET_REGISTER,
      payload: { data: newData, bankList: register.bankList },
    })
  }
  return (
    <View
      state={{
        data: register.data.bank_account,
        availableBank,
      }}
      actions={{
        handleChange,
      }}
    />
  )
}
