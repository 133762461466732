import React from "react"
import PropTypes from "prop-types"
import { Grid, Container, Box } from "@material-ui/core"
import Card from "./components/Card"
import { useResponsive } from "~/hooks"
import { USER_STATUS } from "~/constants"

function FormRow({ data }) {
  const { isMobile } = useResponsive()

  return (
    <React.Fragment>
      {data.map((element, index) => (
        <Grid key={`card_${index}`} item xs={isMobile ? 12 : 4}>
          <Card {...element} />
        </Grid>
      ))}
    </React.Fragment>
  )
}
FormRow.propTypes = {
  data: PropTypes.array,
}

export default function View({ state: { info } }) {
  const status = {
    [USER_STATUS.ACTIVE]: { value: "Ativo", valueColor: "#1BC5BD" },
    [USER_STATUS.PENDING]: { value: "Pendente", valueColor: "#F6C209" },
    [USER_STATUS.ANALYZING]: { value: "Em Análise", valueColor: "#F6C209" },
  }
  const infoArray = [
    {
      title: "Status Afiliação",
      description: "Description Afiliação",
      ...status[info.status],
    },
    {
      title: "Assinaturas Pendentes",
      description:
        "Este é o número de assinaturas que você indicou, mas que ainda estão em período de teste. Ao fim deste período, se não forem canceladas e o pagamento for efetivado, passarão para assinaturas ativas.",
      value: info.subscriptions.trialing,
    },
    {
      title: "Assinaturas Ativas",
      description:
        "Este é o número de assinaturas que você indicou e estão efetivamente ativas e pagamento em dia. É este o número contabilizado para sua comissão.",
      value: (info.subscriptions.paid + info.subscriptions.pending).toString(),
    },
  ]

  const balance = [
    {
      title: "Saldo Pendente",
      description: "Saldo que ficará disponível em breve.",
      value: info.pending / 100,
      valueColor: "#F6C209",
      hasCurrency: true,
    },
    {
      title: "Saldo Disponível",
      description: "Saldo disponível para saque.",
      value: info.available / 100,
      valueColor: "#1BC5BD",
      hasCurrency: true,
    },
    {
      title: "Ganhos Vitalícios",
      description:
        "Este é o valor total em comissões que você já recebeu desde a ativação da sua afiliação.",
      value: (info.transferred + info.available + info.pending) / 100,
      valueColor: "#0094FF",
      hasCurrency: true,
    },
  ]

  return (
    <Container>
      <Box mt={4}>
        <Grid container spacing={2} justifyContent="center">
          <Grid container item xs={12} spacing={3}>
            <FormRow data={infoArray} />
            <FormRow data={balance} />
          </Grid>
          {/* <Grid container item xs={3} spacing={3}>
            <Grid item xs={12}>
              <Banners />
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
    </Container>
  )
}

View.propTypes = {
  state: PropTypes.shape({
    info: PropTypes.any,
  }),
}
