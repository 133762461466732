import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { HelpOutline } from "@material-ui/icons/"
import { Paper, Box, Typography, Tooltip } from "@material-ui/core"
import CurrencyFormat from "react-currency-format"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
    borderRadius: 8,
    fontFamily: "Nunito Sans",
    "& .h4": {
      color: theme.palette.text.primary,
    },
  },
  title: {
    color: "#A0A4A8",
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  helperOutline: {
    color: theme.palette.secondary.main,
  },
}))

export default function Card({
  title,
  description,
  value,
  valueColor,
  hasCurrency,
}) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Box mt={1}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.title}>{title}</Typography>
          <Tooltip title={description}>
            <HelpOutline fontSize="small" className={classes.helperOutline} />
          </Tooltip>
        </Box>
      </Box>
      <Box my={3}>
        <Typography
          variant="h4"
          style={{
            color: valueColor,
            fontSize: 28,
            fontWeight: 600,
            fontFamily: "Nunito Sans",
          }}
        >
          {hasCurrency ? (
            <CurrencyFormat
              value={value}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"R$"}
            />
          ) : (
            <Box>{value}</Box>
          )}
        </Typography>
      </Box>
    </Paper>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  valueColor: PropTypes.string,
  hasCurrency: PropTypes.bool,
}
