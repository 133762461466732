import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { Container } from "@material-ui/core"
import PropTypes from "prop-types"
import { useStyles } from "../styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputMask from "react-input-mask"
import { Autocomplete } from "@material-ui/lab"

export default function StepTwoContainer({
  state: { data, availableBank },
  actions: { handleChange },
}) {
  const classes = useStyles()

  const natureza = [
    { type: "individual", label: "Pessoa Fisica" },
    { type: "corporation", label: "Pessoa Jurídica" },
  ]
  const accountType = [
    { type: "conta_corrente", label: "Conta Corrente" },
    { type: "conta_poupanca", label: "Conta Poupança" },
    { type: "conta_corrente_conjunta", label: "Conta Corrente Conjunta" },
    { type: "conta_poupanca_conjunta", label: "Conta Poupança Conjunta" },
  ]
  const bankCodeValue = availableBank.find((el) => el.code === data.bank_code)
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Typography className={classes.title} variant="h6" gutterBottom>
          Passo 1: <b>Natureza</b>
        </Typography>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined" className={classes.input}>
            <InputLabel id="select-natureza-conta">Natureza</InputLabel>
            <Select
              name="document_type"
              labelId="select-natureza-conta"
              id="demo-simple-select-outlined"
              onChange={(e) => {
                const { name, value } = e.target
                handleChange(name, value)
              }}
              label="Natureza"
              value={data.document_type}
            >
              {natureza.map((el, index) => (
                <MenuItem key={index} value={el.type}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Dados Bancários */}

        <Typography className={classes.title} variant="h6" gutterBottom>
          Passo 2: <b>Dados bancários</b>
        </Typography>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined" className={classes.input}>
            <Autocomplete
              id="bank_code"
              name="bank_code"
              value={
                bankCodeValue
                  ? bankCodeValue
                  : { code: "", name: "Selecione um banco" }
              }
              options={availableBank}
              getOptionDisabled={(option) => option.code === ""}
              getOptionLabel={(option) => {
                if (option.code !== "") return `${option.code} - ${option.name}`
                return option.name
              }}
              style={{ width: "100%" }}
              onChange={(event, newValue) => {
                handleChange("bank_code", newValue ? newValue.code : "")
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Banco"
                    placeholder="Digite aqui o banco que deseja utilizar"
                    variant="outlined"
                    value={
                      bankCodeValue
                        ? bankCodeValue
                        : { code: "", name: "Selecione um banco" }
                    }
                  />
                )
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de conta
            </InputLabel>
            <Select
              name="account_type"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={(e) => {
                const { name, value } = e.target
                handleChange(name, value)
              }}
              label="Tipo de Conta"
              value={data.account_type}
            >
              <MenuItem value="">
                <em>Selecione o Tipo de Conta</em>
              </MenuItem>

              {accountType.map((el, index) => (
                <MenuItem key={index} value={el.type}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={9} sm={9}>
          <InputMask
            mask="99999"
            onChange={(e) => {
              const { value, name } = e.target
              handleChange(name, value)
            }}
            maskPlaceholder={null}
            className={classes.input}
            value={data.agency_number}
          >
            <TextField
              fullWidth
              id="agency_number"
              name="agency_number"
              label="Agência"
              variant="outlined"
            />
          </InputMask>
        </Grid>

        <Grid item xs={3} sm={3}>
          <InputMask
            mask="9"
            onChange={(e) => {
              const { value, name } = e.target
              handleChange(name, value)
            }}
            maskPlaceholder={null}
            value={data.agency_digit}
            className={classes.input}
          >
            <TextField
              fullWidth
              id="agency_digit"
              name="agency_digit"
              label="Dígito"
              variant="outlined"
            />
          </InputMask>
        </Grid>

        <Grid item xs={9} sm={9}>
          <InputMask
            mask="9999999999999"
            onChange={(e) => {
              const { value, name } = e.target
              handleChange(name, value)
            }}
            maskPlaceholder={null}
            value={data.account_number}
            className={classes.input}
          >
            <TextField
              fullWidth
              id="account_number"
              name="account_number"
              label="Conta"
              variant="outlined"
            />
          </InputMask>
        </Grid>

        <Grid item xs={3} sm={3}>
          <InputMask
            mask="99"
            onChange={(e) => {
              const { value, name } = e.target
              handleChange(name, value)
            }}
            maskPlaceholder={null}
            className={classes.input}
            value={data.account_digit}
          >
            <TextField
              fullWidth
              id="account_digit"
              name="account_digit"
              label="Dígito"
              variant="outlined"
            />
          </InputMask>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.input}
            onChange={(e) => {
              const { name, value } = e.target
              handleChange(name, value)
            }}
            inputProps={{ maxLength: 30 }}
            value={data.owner_name}
            variant="outlined"
            id="owner_name"
            name="owner_name"
            label="Nome do Favorecido"
            helperText="O campo deve conter no máximo 30 caracteres e somente letras, podendo usar abreviações"
            fullWidth
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputMask
            mask={
              data.document_type === "corporation"
                ? "99.999.999/9999-99"
                : "999.999.999-99"
            }
            onChange={(e) => {
              const { value, name } = e.target
              handleChange(name, value)
            }}
            value={data.document_number}
            maskPlaceholder={null}
            className={classes.input}
          >
            <TextField
              fullWidth
              id="document_number"
              name="document_number"
              label={data.document_type === "corporation" ? "CNPJ" : "CPF"}
              variant="outlined"
            />
          </InputMask>
        </Grid>
      </Grid>
    </Container>
  )
}

StepTwoContainer.propTypes = {
  state: PropTypes.shape({
    data: PropTypes.any,
    availableBank: PropTypes.any,
  }),
  actions: PropTypes.shape({
    handleChange: PropTypes.func,
  }),
}
