import React from "react"
import AppBar from "../Dashboard/components/AppBar"
import List from "./components/List"

function WithdrawalContainer() {
  return (
    <div>
      <AppBar isPanel={false} />
      <List />
    </div>
  )
}

export default WithdrawalContainer
